<template>
  <div class="common_sub_container">
    <h2>DELIVERY PERFORMANCE REPORTS</h2>
    <section>
      <div class="form_section1 adj_16">
        <table>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  <!-- Choose a Report Type -->
                  Choose a Transport Mode
                  <span></span>
                </label>
                <select v-model="post_data.booking_type" @change="resetData">
                  <option value="all">All</option>
                  <option value="Courier">Courier</option>
                  <option value="Messenger">Messenger</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Choose Period
                  <span></span>
                </label>
                <flat-pickr
                  class="date_for_pos_fixed"
                  v-model="daterange"
                  :config="configDate"
                  placeholder="Date"
                  name="birthdate"
                  @on-close="dateChanged"
                ></flat-pickr>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Request Hub
                  <span></span>
                </label>
                <select v-model="post_data.division" @change="resetData">
                  <option value="all">All</option>
                  <option
                    v-for="(division,i) in divisions"
                    :key="'divisions'+i"
                    :value="division.name"
                  >{{division.name}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Request Branch
                  <span></span>
                </label>
                <select v-model="post_data.branch" @change="resetData">
                  <option value="all">All</option>
                  <option
                    v-for="(unit,i) in units"
                    :key="'units'+i"
                    :value="unit.name"
                  >{{unit.name}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Group By
                  <span></span>
                </label>
                <select v-model="post_data.groupby" @change="resetData">
                  <option value="branch">Branch</option>
                  <option value="division">Hub</option>
                  <option value="delivery_service">Service</option>
                  <option value="type_of_good">Type of Goods</option>
                  <option value="provider_id">Carrier</option>
                  <option value="delivery_country">Destination Country</option>
                  <option value="delivery_state">Destination State/Province</option>
                  <option value="booking_type">Transport Mode</option>
                  <option value="delivery_type">Delivery Type</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Delivery Type
                  <span></span>
                </label>
                <select v-model="post_data.delivery_type" @change="resetData">
                  <option value="all">All</option>
                  <!-- <option value="standard">Standard</option>
                  <option value="priority">Priority</option>
                  <option value="emergency">Emergency</option> -->
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Type of Goods
                  <span></span>
                </label>
                <select v-model="post_data.type_of_good" @change="resetData">
                  <option value="all">All</option>
                  <option
                    v-for="(good,i) in goods"
                    :key="'goods'+i"
                    :value="good.id"
                  >{{good.type}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Carrier
                  <span></span>
                </label>
                <select v-model="post_data.delivery_provider" @change="fetchService">
                    <option value="all">All</option>
                    <option
                      v-for="(carrier,i) in carriers"
                      :key="'carriers'+i"
                      :value="carrier.id"
                    >{{carrier.name}}</option>
                  </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Service
                  <span></span>
                </label>
                <select v-model="post_data.delivery_service" @change="resetData">
                    <option value="all" disabled>All</option>
                    <option
                      v-for="(service,i) in services"
                      :key="'services'+i"
                      :value="service.name"
                    >{{service.name}}</option>
                  </select>
              </div>
            </td>
            <!-- <td>
              <div class="label_and_element_wrapper">
                <label>
                  Ship Type
                  <span></span>
                </label>
                <select>
                  <option>Standard</option>
                  <option>DG</option>
                  <option>Food</option>
                </select>
              </div>
            </td> -->
            <td>
              <div class="label_and_element_wrapper">
                <label>Receiver Name</label>
                <input type="text" placeholder="Search By Receiver Name" v-model="post_data.delivery_name"/>
              </div>
            </td>

            <td>
              <div class="label_and_element_wrapper">
                <label>Receiver Address</label>
                <input type="text" placeholder="Search By Receiver Address" v-model="post_data.delivery_address"/>
              </div>
            </td>

            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Status
                  <span></span>
                </label>
                <select v-model="post_data.status" @change="resetData">
                  <option value="all">All</option>
                  <option value="Not Send">Not Send</option>
                  <option value="Dispatched">Dispatched</option>
                  <option value="Picked Up">Picked Up</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Refused">Refused</option>
                  <option value="Received">Received</option>
                  <option value="Issued">Issued</option>
                  <option value="In Transit">In Transit</option>
                  <option value="Received">Received</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Slip Generated">Slip Generated</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  GL Code
                  <span></span>
                </label>
                <input type="text" v-model="post_data.code_value">
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <a class="link_bt bt_save" @click="fetchReport">Search</a>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </section>
    <section  v-if="show_data" class="reports_to_print">
      <div class="despatch_summary_wrapper"  v-if="Object.keys(reports).length > 0">
        <header>
          <h3>Registry Dispatch System Monthly Summary</h3>
          <div class="right_part">
            <a title="Download" ref="download" @click="downloadReport">
              <i class="material-icons">arrow_drop_down_circle</i>
            </a>
            <a title="Print" @click="printRecord">
              <i class="material-icons">print</i>
            </a>
          </div>
        </header>
        <div class="despatch_summary_table_container" v-for="(report,key,i) in reports" :key="'delivery_report'+i">
          <h2>{{key}}</h2>
          <table>
               <tr>
              <td><b>Hub</b></td>
              <td><b>Branch</b></td>
              <td><b>Con No</b></td>
              <td><b>Carrier AWB</b></td>
              <td><b>Requester</b></td>
              <!-- <td><b>Cost Center</b></td> -->
              <td><b>Receiver Name</b></td>
              <td><b>Receiver Address</b></td>
              <td><b>Transport Mode</b></td>
              <td><b>Type Of Goods</b></td>
              <td><b>Carrier</b></td>
              <td><b>Service</b></td>
              <td><b>Description of Goods</b></td>
              <td><b>Qty</b></td>
              <td><b>Weight</b></td>
              <td><b>Remarks/Notes</b></td>
              <td><b>Shipment Cost ( Cost + Extra Charges)</b></td>
              <td><b>Shipment Status</b></td>
              <td><b>Code</b></td>
              <td><b>Code Value</b></td>
              <td><b>Requested Date</b></td>
              <td><b>Dispatch Date</b></td>
              <td><b>Delivered Date</b></td>
              <td><b>Delivery Name</b></td>
              <td v-if="auth().user_type == 'user'"><b>Billed Amount</b></td>
              <td v-if="auth().user_type != 'user'"><b>Courier Charged Amount</b></td>
              <td v-if="auth().user_type != 'user'"><b>Actual Courier Invoiced Amount</b></td>
              <td><b>Invoice Status</b></td>
              <td><b>Invoice Comment</b></td>
            </tr>
            <tr v-for="(courier,i) in report" :key="i">
              <td>{{courier.division}}</td>
              <td>{{courier.branch}}</td>
              <td>CDBSA{{courier.booking_id}}</td>
              <td>{{courier.airway_bill}}</td>
              <td>{{courier.requester_name}}</td>
              <!-- <td>{{courier.cost_center_name}}</td> -->
              <td>{{courier.delivery_name}}</td>
              
             <!-- <td>{{courier.delivery_address_1}}, {{courier.delivery_address_2}}, {{courier.delivery_city}}, {{courier.delivery_state}} {{courier.delivery_country}}</td>-->
              <td>{{courier.address}}</td>
              <td>{{courier.booking_type}}</td>
              <td>{{courier.type_of_good}}</td>
               <td>{{courier.provider_id}}</td>
              <td>{{courier.delivery_service}}</td>
              <td>{{courier.goods_description}}</td>
              <td>{{courier.item_qty}}</td>
              <td>{{courier.item_weight}}</td>
              <td>{{courier.remarks}}</td>
              <td>{{courier.total_cost  || "0.000"}} ({{courier.cost  || "0.000"}} + {{courier.other_charges  || "0.000"}})</td>
              <td>{{courier.status}}</td>
              <td>{{courier.custom_code}}</td>
              <td>{{courier.custom_code_value}}</td>
              <td>{{moment(courier.request_date,"YYYY-MM-DD").format("DD-MM-YYYY")}}
              <!-- {{moment((courier.request_date),["DD-MM-YYYY", "MM-DD-YYYY" ]).format("MM-DD-YYYY")}} -->
              </td>
              
              <!-- <td>{{courier.request_date}}</td> -->

              <td>{{courier.dispatched_date?moment(courier.dispatched_date,"YYYY-MM-DD").format("DD-MM-YYYY"):""}}</td>
              
              <td>{{moment(courier.status_date,"MM-DD-YYYY").format("DD-MM-YYYY")}} {{courier.status_time}}</td>
              <!-- <td v-if="courier.is_courier_status==1">{{courier.is_actual_courier_amount== 1? 'Yes' : 'Error'}}</td> -->
              <td>{{courier.delivery_name}}</td>
              <td v-if="auth().user_type == 'user'">{{courier.cost}}</td>
              <td v-if="auth().user_type != 'user'">{{courier.courier_rate}}</td>
              <td v-if="auth().user_type != 'user'">{{courier.actual_courier_amount}}</td>
              <td v-if="courier.is_courier_status==1" >Yes</td>
              <td v-if="courier.is_courier_status==0" >No</td>
              <td>{{courier.courier_amount_comment}}</td>
             <!--  <td v-if="courier.is_courier_status==1 && courier.is_actual_courier_amount==0">{{courier.courier_amount_comment}}</td> -->
             
            </tr>
          </table>
        </div>
      </div>
      <h4 v-if="Object.keys(reports).length == 0">No data found</h4>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  name: "ReportsDeliveryPerformance",
  components: {
    flatPickr
  },
  data() {
    return {
      daterange: [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      ],
      date: null,
      enableTime: true,
      carriers: [],
      costcenters: [],
      goods: [],
      services: [],
      divisions: [],
      units: [],
      reports: {},
      post_data: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        booking_type: "all",
        type_of_good: "all",
        delivery_provider: "all",
        delivery_service: "all",
        groupby: "branch",
        delivery_type: "all",
        division: "all",
        branch: "all",
        status: "all",
        delivery_address: "",
        delivery_name :"",
        code_value :""
      },
      api_url: "",
      configDate: {
        mode: "range",
        format: "d-m-Y",
          altFormat: "d-m-Y",
          altInput: true
      },
      show_data: false
    }
  },
  created() {
    this.api_url = process.env.VUE_APP_API_URL;
    this.$emit("breadCrumbData", ["Reports", "Delivery Performance Report"]);
    this.fetchCarrier();
    this.fetchGood();
    this.fetchDivision();
    this.fetchUnit();
    this.fetchService();
  },
  methods: {


    dateChanged(daterange) {
      this.resetData();
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
    },
    fetchDivision() {
      this.axios
        .get("/api/division/")
        .then(response => {
          console.log(response);
          this.divisions = response.data.divisions;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    fetchUnit() {
      this.axios
        .get("/api/branch/")
        .then(response => {
          console.log(response);
          this.units = response.data.branches;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    fetchGood() {
      this.axios
        .get("/api/goods/")
        .then(response => {
          console.log(response);
          this.goods = response.data.goods;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    fetchCarrier() {
      this.axios
        .get("/api/provider/")
        .then(response => {
          console.log(response);
          this.carriers = response.data.providers;

        })
        .catch(error => {
          this.toast.error();
        });
    },
    fetchService() {
      this.resetData();
      this.services = [];
      if (this.post_data.delivery_provider != "") {
        this.axios
          .get("/api/provider/services/" + this.post_data.delivery_provider)
          .then(response => {
          console.log(response);
            this.services = response.data.services;
              if(this.services.length > 0){

              this.post_data.delivery_service = this.services[0].name;
            }
          })
          .catch(error => {
            this.toast.error();
          });
      }
    },
    resetData() {
      this.show_data = false;
      this.reports = {};
    },
    fetchReport() {
      console.log(this.post_data);
      this.resetData();
      this.axios
        .post("/api/reports/getdeliveryperformancereports",this.post_data)
        .then(response => {
          console.log(response);
          this.show_data = true;
          this.reports = response.data.data;
          console.log(this.reports);
            console.log('this.report');
        })
        .catch(error => {
          console.log(e);
        });
    },
    downloadReport() {
      let method = "POST";
      let input;
      let form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute(
        "action",
        this.api_url +
          "/api/reports/getdeliveryperformancereports/download?token=" +
          localStorage.token
      );
      Object.keys(this.post_data).forEach(item => {
        input = document.createElement("input");
        input.type = "text";
        input.name = item;
        input.value = this.post_data[item];
        form.appendChild(input);
      });
      this.$refs.download.appendChild(form);
      form.submit();
      this.$refs.download.removeChild(form);
    },
    printRecord() {
      window.print();
    }
  }
};
</script>
<style>
.flatpickr-calendar.lol {
  border: 5px solid red;
}
</style>
<style scoped  >
.form_section1.adj_16 td {
  width: 16.6%;
}
.link_bt {
  margin-left: 10px;
}
.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}
.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}
.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}
.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}
.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}
.despatch_summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  overflow-x:auto;
}
.despatch_summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.despatch_summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>